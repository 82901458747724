.App {
  width: 100vw;
  height: 100vh;
  background-color: #faf9fb;
  overflow-x: hidden;
}

.App::-webkit-scrollbar{
  display: none;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  height: 60px;
  width: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
}

.logo{
  min-width: 40px;
  height: 40px; 
}

.srcbox {
  background: #fff;
  border-radius: 25px;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.15) ;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 8px 15px;
  width: "100vw";
}

.srcbox .searchicon {
  color: #787a84;
  font-size: 20px;
}

.srcbox input{
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  background: transparent;
  color: #787a84;
  padding-left: 5px;
  font-size: 14px;
  font-weight: 500;
  
}

.qr{
  background: #fff;
  border-radius: 25px;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.15) ;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 8px 8px;
} 

.qr .title{
  font-size: 12px;
  margin-left: 5px;
  font-weight: 500;
  color: #787a84; 
  display: none;
}

.ar{
  background: #fff;
  border-radius: 25px;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.15) ;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 8px 8px;
} 


.ar .title{
  font-size: 12px;
  margin-left: 5px;
  font-weight: 500;
  color: #787a84; 
  display: none;
}